<template>
  <div class="BlogShow">
    <HeadOrders
      :PathPageFrom="$t('Home')"
      :PathPageCenter="false"
      :PathPageTo="$t('BlogShow')"
    />
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="col-12">
            <img
              width="100%"
              :src="
                lang == 'ar'
                  ? `${BlogsDetails.ar_image}`
                  : `${BlogsDetails.en_image}`
              "
            />
          </div>
          <small
            class="col-12 d-flex colsmall"
            style="
              margin-top: 20px;
              font-size: 12px;
              color: #797e82;
              display: inline-block;
              margin-top: 26px;

            "
            v-if="BlogsDetails.author"
          >
          <span style=" line-height: 33px;padding-top: 5px;">
            {{ BlogsDetails.author != 'غير معروف' && BlogsDetails.author.toLowerCase() != 'unknown' ? $t("By") + ' ' + BlogsDetails.author + ' - ' : ''}}
            {{ BlogsDetails.published_at }}
          </span>

            <div
              style="

                display: flex;padding-top: 5px;

              "
            >
              <span
                style="margin-inline-end: 10px; line-height: 33px"
                class="d-flex mr-2"
              >
                <svg
                style="margin:10px"
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.267 6.50011C11.267 7.75199 10.2521 8.76684 9.00024 8.76684C7.74836 8.76684 6.73351 7.75199 6.73351 6.50011C6.73351 5.24823 7.74836 4.23338 9.00024 4.23338C10.2521 4.23338 11.267 5.24823 11.267 6.50011Z"
                    fill="#0349A8"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.138 2.01883C15.6426 3.09243 16.7997 4.58339 17.4662 6.30744C17.5113 6.43194 17.5113 6.56828 17.4662 6.69278C16.7997 8.41683 15.6426 9.90779 14.138 10.9814C12.6333 12.055 10.8471 12.6642 9 12.7336C7.15291 12.6642 5.36665 12.055 3.862 10.9814C2.35735 9.90779 1.20027 8.41683 0.53376 6.69278C0.488747 6.56828 0.488747 6.43194 0.53376 6.30744C1.20027 4.58339 2.35735 3.09243 3.862 2.01883C5.36665 0.945236 7.15291 0.336062 9 0.266602C10.8471 0.336062 12.6333 0.945236 14.138 2.01883ZM6.95359 9.56278C7.55933 9.96752 8.27148 10.1835 9 10.1835C9.97645 10.182 10.9125 9.79349 11.6029 9.10304C12.2934 8.41259 12.6819 7.47656 12.6834 6.50011C12.6834 5.7716 12.4674 5.05944 12.0627 4.4537C11.6579 3.84797 11.0826 3.37585 10.4096 3.09706C9.73653 2.81827 8.99591 2.74532 8.2814 2.88745C7.56688 3.02958 6.91055 3.38039 6.39542 3.89553C5.88028 4.41067 5.52946 5.06699 5.38734 5.78151C5.24521 6.49602 5.31816 7.23664 5.59695 7.9097C5.87574 8.58276 6.34785 9.15804 6.95359 9.56278Z"
                    fill="#0349A8"
                  />
                </svg>
                <div class="d-flex">
                  <span>{{ BlogsDetails.views }}</span> <span> {{ $t("views") }}</span></div>

              </span>
              <spn style="line-height: 33px">/</spn>
              <span style="margin-inline-start: 10px; line-height: 33px;" class="d-flex">
                <svg
                 style="margin:6px"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.5 8.92171C2.5 7.05505 2.5 6.12171 2.86333 5.40838C3.18291 4.7812 3.69282 4.27129 4.32 3.95171C5.03333 3.58838 5.96667 3.58838 7.83333 3.58838H12.1667C14.0333 3.58838 14.9667 3.58838 15.68 3.95171C16.3072 4.27129 16.8171 4.7812 17.1367 5.40838C17.5 6.12171 17.5 7.05505 17.5 8.92171V9.92171C17.5 11.7884 17.5 12.7217 17.1367 13.435C16.8171 14.0622 16.3072 14.5721 15.68 14.8917C14.9667 15.255 14.0333 15.255 12.1667 15.255H6.17833C5.95734 15.2551 5.74541 15.3429 5.58917 15.4992L3.9225 17.1659C3.3975 17.6909 2.5 17.3192 2.5 16.5767V11.0884V8.92171ZM7.5 6.92171C7.27899 6.92171 7.06702 7.00951 6.91074 7.16579C6.75446 7.32207 6.66667 7.53403 6.66667 7.75505C6.66667 7.97606 6.75446 8.18802 6.91074 8.3443C7.06702 8.50058 7.27899 8.58838 7.5 8.58838H12.5C12.721 8.58838 12.933 8.50058 13.0893 8.3443C13.2455 8.18802 13.3333 7.97606 13.3333 7.75505C13.3333 7.53403 13.2455 7.32207 13.0893 7.16579C12.933 7.00951 12.721 6.92171 12.5 6.92171H7.5ZM7.5 10.255C7.27899 10.255 7.06702 10.3428 6.91074 10.4991C6.75446 10.6554 6.66667 10.8674 6.66667 11.0884C6.66667 11.3094 6.75446 11.5214 6.91074 11.6776C7.06702 11.8339 7.27899 11.9217 7.5 11.9217H10C10.221 11.9217 10.433 11.8339 10.5893 11.6776C10.7455 11.5214 10.8333 11.3094 10.8333 11.0884C10.8333 10.8674 10.7455 10.6554 10.5893 10.4991C10.433 10.3428 10.221 10.255 10 10.255H7.5Z"
                    fill="#0349A8"
                  />
                </svg>
                <div class="d-flex">
                  <span>{{ BlogComments.length }}</span> <span> {{ $t("SumComments") }}</span></div>

              </span>
              <div>
                <!-- <button
                  class="btn btn-sm btn-primary share-btn p-2"
                  @click="showShareMenu = !showShareMenu"
                  style="height: 38px"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 10.5L11.2 5.25V7.875C8.8 7.875 4 9.45 4 15.75C4 14.8748 5.44 13.125 11.2 13.125V15.75L16 10.5Z"
                      fill="white"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>{{ $t("share") }}</span>
                </button> -->

              </div>
            </div>
            <div
                  class=
                    'social-share-buttons'

                >
                  <span @click="shareBlog('facebook')">
                    <svg
                      class="facebook"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"
                      />
                    </svg>
                  </span>
                  <span @click="shareBlog('x')">
                    <svg
                      class="x"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                      />
                    </svg>
                  </span>
                  <span @click="shareBlog('linkedin')">
                    <svg
                      class="linkedin"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                      />
                    </svg>
                  </span>
                  <span @click="shareBlog('whatsapp')">
                    <svg
                      class="whatsapp"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                      />
                    </svg>
                  </span>
                </div>
          </small>
          <div class="col-12" style="margin-top: 30px">
            <h1 class="title">{{ BlogsDetails.title }}</h1>
          </div>
          <div class="col-12 mt-4">
            <BlogInformation
              :BlogComments="BlogComments"
              :BlogsDetails="BlogsDetails"
            />
          </div>

          <div class="col-12 mt-4">
            <div class="contnet" v-html="BlogsDetails.content"></div>
          </div>
          <strong>{{ likes }} {{ $t("Like") }}</strong>

          <div class="col-12 mt-4">
            <div class="like-btn">
              <button
                @click="toggleLike"
                :class="{ liked: BlogsDetails.is_liked }"
                class="btn"
              >
                <svg class="icon ml-2" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  />
                </svg>
                {{ $t("likeit") }}
              </button>
              <VerfyLogin v-if="this.$store.state.VerfyLogin == true" />
            </div>
          </div>

          <div class="d-flex mt-4">
            <span
              v-for="item in BlogsDetails.tags"
              class="badge rounded-pill bg-secondary"
              style="margin-right: 7px"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="blog_sub">
        
            <div class="col-12">
              <div class="subscribe-news">
                <div class="title">
                  {{
                    this.$i18n.locale == "ar"
                      ? "اشترك في النشرة الإخبارية"
                      : "Subscribe to the newsletter"
                  }}
                </div>
                <p>{{ $t("NewsletterSubscription") }}</p>
                <Form
                  class="newslett"
                  @submit="HandelSubmit"
                  :validation-schema="schema"
                >
                  <Field name="email" type="email" :placeholder="$t('Email')" />
                  <ErrorMessage name="email" class="text-white" /> <br />
                  <button class="btn-main mt-2" type="submit">
                    {{ $t("Subscribe") }}
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8" style="display: flex; flex-direction: column;">
          <div class="comment-section mt-5 mb-5">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.7 12.2H29.1V23.9H12.2V26.5C12.2 27.215 12.785 27.8 13.5 27.8H27.8L33 33V13.5C33 12.785 32.415 12.2 31.7 12.2ZM26.5 20V8.3C26.5 7.585 25.915 7 25.2 7H8.3C7.585 7 7 7.585 7 8.3V26.5L12.2 21.3H25.2C25.915 21.3 26.5 20.715 26.5 20Z"
                fill="#0349A8"
              />
            </svg>
            <span>
              {{
                this.$i18n.locale == "ar" ? `اترك تعليقك !` : `left comment !`
              }}
            </span>

            <Form
              ref="commentForm"
              class="comment-form mt-2 mb-2"
              @submit="onSubmit"
              :validation-schema="schema"
            >
              <div class="row form-row mb-2" v-if="userdata">
                <div class="col-md-12" style="height: 60px; padding: 14px">
                  <div class="row">
                    <div class="col-md-10">
                      <Field
                        name="comment"
                        :placeholder="$t('comment')"
                        class="p-4 mt-2"
                        style="height: 35px"
                      />
                    </div>

                    <div
                      class="col-md-2 btn-col"
                      style="height: 60px; background-color: white;"
                    >
                      <button type="submit" class="submit-btn">
                        {{ $t("Send") }}
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row form-row mb-2" v-else>
                <div class="col-md-4">
                    <Field
                      name="user_name"
                      type="text"
                      :placeholder="$t('FullName')"
                      style="height: 49px"
                    />
                    <ErrorMessage name="user_name" class="error-message" />
                  </div>
                <div class="col-md-8" >
                  <div class="row">
                    <div class="col-md-10 paddtop">
                      <Field
                        name="comment"
                        :placeholder="$t('comment')"
                        class="p-4 "
                        style="height: 35px"
                        as="textarea"
                      />
                    </div>

                    <div
                      class="col-md-2 btn-col"
                      style="height: 60px; background-color: white"
                    >
                      <button type="submit" class="submit-btn">
                        {{ $t("Send") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div class="comments-container">
              <div
                class="comment-card"
                v-for="item in BlogComments"
                :key="item.id"
              >
                <p class="comment-text">{{ item.comment }}</p>
                <div class="comment-header">
                  <div class="d-flex align-items-center">
                    <img :width="40" :height="40" :src="item.user_image" />
                    <strong>{{ item.user_name }}</strong>
                  </div>
                  <div class="d-flex align-items-center">
                    <div
                      class="comment-actions d-flex"
                      v-if="userdata && userdata.name == item.user_name"
                    >
                      <button
                        class="btn btn-sm btn-primary edit-btn"
                        @click="editComment(item)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit"
                        >
                          <path
                            d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                          ></path>
                          <path
                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                          ></path>
                        </svg>
                      </button>
                      <UpdateComment
                        v-if="
                          this.$store.state.updateComment == true &&
                          currentEditedItem === item
                        "
                        :item="currentEditedItem"
                        :currentPage="currentPage"
                        @send-array="handleArrayFromChild"
                      />
                      <button
                        class="btn btn-sm btn-danger delete-btn"
                        @click="deleteComment(item.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path
                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                          ></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav v-if="BlogComments.length > 0" aria-label="Page navigation" class="navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="fetchComments(currentPage - 1)"
                  >{{ $t("Previous") }}</a
                >
              </li>
              <li
                class="page-item"
                v-for="page in pagesCount"
                :key="page"
                :class="{ active: currentPage === page }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="fetchComments(page)"
                  >{{ page }}</a
                >
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === pagesCount }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="fetchComments(currentPage + 1)"
                  >{{ $t("Next") }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <router-link
          to="/contact"
          class="col-md-4 d-flex justify-content-center info mb-4 mt-4"
        >
          <img :src="imageSrc" alt="joinFrame" class="w-100" />
          <div class="content">
            <p>
              {{
                $t(
                  "Start your journey in the business world with confidence and let us be part of your success"
                )
              }}
            </p>
            <button class="btn-seconde">{{ $t("Free consultation") }}</button>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { configure } from "vee-validate";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  name: "Blogs Details",
  data() {
    return {
      showShareMenu: true,
      BlogsDetails: [],
      BlogComments: [],
      currentPage: 1,
      pagesCount: 0,
      userdata: null,
      currentEditedItem: null,
      likes: 0,
      imageSrc: `${process.env.VUE_APP_BASE_URL}/images/static_content/default_frame.png`,
      lang: cookie.get("lang"),
    };
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      user_name: yup.string().optional(),
      comment: yup.string().required(),
    });
    return {
      schema,
    };
  },
  components: {
    HeadOrders: defineAsyncComponent(() =>
      import("@/components/Global/HeadOrders.vue")
    ),
    Share: defineAsyncComponent(() =>
      import("@/views/Client/blogs/SocialShare.vue")
    ),
    UpdateComment: defineAsyncComponent(() =>
      import("@/views/Client/blogs/UpdateComments.vue")
    ),
    VerfyLogin: defineAsyncComponent(() =>
      import("@/views/Client/blogs/VerfyLogin.vue")
    ),
    BlogInformation: defineAsyncComponent(() =>
      import("@/views/Client/blogs/BlogInformation.vue")
    ),
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {
    this.userdata = cookie.get("Userdata");
    this.$store.dispatch("GetBlogsDetails", this.$route.params.id).then((r) => {
      this.BlogsDetails = this.$store.state.BlogsDetails;
      this.likes = this.BlogsDetails["likes"];
    });
    this.fetchComments(this.currentPage);
  },

  methods: {
    shareBlog(type) {
      const baseUrl = `${window.location.href}`;
      const blogUrl = encodeURIComponent(baseUrl);
      let url = "";
      switch (type) {
        case "facebook":
          url = `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`;
          break;
        case "x":
          url = `https://x.com/intent/post?url=${blogUrl}`;
          break;
        case "linkedin":
          url = `https://www.linkedin.com/sharing/share-offsite/?url=${blogUrl}`;
          break;
        case "whatsapp":
          const text = encodeURIComponent(
            "شاهد هذا المقال عبر مدونة إتمام -  "
          );
          url = `https://api.whatsapp.com/send?text=${text} ${blogUrl}`;
          break;
      }
      window.open(url, "_blank");
      this.showShareMenu = false;
    },
    fetchComments(page) {
      let blogId = this.$route.params.id;
      this.$store
        .dispatch("GetBlogsComments", { blogId: blogId, page: page })
        .then((r) => {
          this.BlogComments = this.$store.state.BlogComments;
          this.pagesCount = this.$store.state.pagesCount;
          this.currentPage = page;
        });
    },
    async onSubmit(values) {
      let blogId = this.$route.params.id;
      this.userdata = cookie.get("Userdata");
      const formData = {
        user_name: values.user_name || "",
        comment: values.comment,
      };
      formData["blog_id"] = blogId;

      if (this.userdata && cookie.get("Token")) {
        const token = cookie.get("Token");

        await axios
          .post(`user/blogs/comments`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            this.$store
              .dispatch("GetBlogsComments", {
                blogId: blogId,
                page: this.currentPage,
              })
              .then((r) => {
                this.BlogComments = this.$store.state.BlogComments;
                this.pagesCount = this.$store.state.pagesCount;
              });
            notify({
              type: "success",
              title:
                this.$i18n.locale == "ar"?
                  "إضافة تعليق": "Add a comment",
              text: this.$i18n.locale == "ar"? "تم إضافة التعليق بنجاح":"Comment added successfully",
            });
          })
          .catch(function (error) {
            if (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
      } else {
        await axios
          .post(`user/blogs/comments`, formData)
          .then((res) => {
            this.$store
              .dispatch("GetBlogsComments", {
                blogId: blogId,
                page: this.$store.state.pagesCount,
              })
              .then((r) => {
                this.BlogComments = this.$store.state.BlogComments;
                this.pagesCount = this.$store.state.pagesCount;
              });
            notify({
              type: "success",
              title:
                this.$i18n.locale == "ar"?
                  "إضافة تعليق": "Add a comment",
              text:
                this.$i18n.locale == "ar"?
                  "تم إرسال التعليق بانتظار موافقة القسم المسؤول":"The comment has been sent, awaiting approval from the responsible department.",
            });
          })
          .catch(function (error) {
            if (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
      }
      this.$refs.commentForm.resetForm();
    },
    toggleLike() {
      this.BlogsDetails.is_liked = !this.BlogsDetails.is_liked;
      if (this.BlogsDetails.is_liked) {
        this.likes++;
      } else {
        this.likes--;
      }
      let blogId = this.$route.params.id;
      this.$store.dispatch("ToggleLike", blogId);
    },
    deleteComment(id) {
      let blogId = this.$route.params.id;
      this.$store.dispatch("DeleteComment", id).then((r) => {
        this.$store
          .dispatch("GetBlogsComments", {
            blogId: blogId,
            page: this.$store.state.pagesCount,
          })
          .then((r) => {
            this.BlogComments = this.$store.state.BlogComments;
            this.pagesCount = this.$store.state.pagesCount;
          });
      });
    },
    editComment(item) {
      this.$store.state.updateComment = true;
      this.currentEditedItem = item;
    },
    handleArrayFromChild(array) {
      this.BlogComments = array;
      this.$store.state.updateComment = false;
    },
  },
};
</script>
<style scoped lang="scss">
.colsmall{
  @media (max-width:1024px) {
        flex-direction:column;
      }
}
</style>
